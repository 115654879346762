import React  from "react"
import Img from "gatsby-image"

const Books = ({ value, detail = false  }) => {
  
  return (
  <div class="books">
    {value.map(book => (
      <Book value={book} detail={detail} />
    ))}
  </div>
)
    }
const Book = ({ value, detail = false }) => {

  let theDescription = detail ? value.description : value.short_description

  return (
    <div class={"book " + (detail ? "detail":"home" )}>
      
      <div class="cover">
        <div class="inner">
        {(value.cover !== null  && 
        <Img
          fluid={value.cover.localFile.childImageSharp.fluid}
          alt={value.title}
          fadeIn={true}
         />)}
         </div>
      </div>
      <div class="description">
        <h3 dangerouslySetInnerHTML={{ __html: value.title }} />
        <h4 dangerouslySetInnerHTML={{ __html: value.publisher + ", " + value.year }} />
        <div dangerouslySetInnerHTML={{ __html: theDescription }} />
      </div>
    </div>
  )
}



export default Books
