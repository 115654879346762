import React from "react"

const LinkButton = ({ value }) => {
  const theLink = value.link

  if ( theLink !== "") {
    return <a href={value} class="link"></a>
  } else {
    return null
  }
}



 const Courses = ({ value }) => (
    <div class="courses">
    {value.map(course => (
      <Course value={course} />
    ))}
  </div>
  )
  


const Course = ({ value }) => (
  <div class="item">
  <span class="inner">
    <h4 dangerouslySetInnerHTML={{ __html: value.course_name }} />
    <span class="pub" dangerouslySetInnerHTML={{ __html: value.course_type }} />
    <span dangerouslySetInnerHTML={{ __html: value.school }} />
    <span dangerouslySetInnerHTML={{ __html: value.dates }} />
    <LinkButton value={"abc"}></LinkButton>
  </span>
  
</div>
  )
  

  export default Courses