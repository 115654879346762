import React from "react"





const SiteNav = ({ children }) => (
  <ul class="sitenav">
    {children}
  </ul>
)



export default SiteNav