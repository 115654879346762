//import stuff
import React, { Component } from 'react';
    
class BurgerNav extends Component {
    constructor(props) {
        super(props);
        this.state = { showModal: false };
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    }

    render() {
        // the modal you will toggle on and off
        const modal = (
            <div className="modal">
               {this.props.children}
            </div>
        );


        return (
            // wrapper div of component
            <div className="burgernav">

                <button class={"hamburger  hamburger--collapse " + (this.state.showModal ? 'is-active':'')} type="button"  onClick={this.handleClick}>
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
                </button>

                {this.state.showModal ? modal : ''}
            </div>
        ); 
    }
}
// export the class so you can call it elsewhere
export default BurgerNav;