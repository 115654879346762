import React from "react"
import BurgerNav from "../components/burger.js"
import SiteNav from "../components/sitenav.js"
import { Link } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

const ListLink = props => (
  <li>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

const Navigation = props => (
  <div class="block">


    <div class=" nav">
      <div class="sitetitle">
        <Link to="/"><h3>Sarah Kovner</h3></Link>
      </div>




      <SiteNav>

        <ListLink to="/books/">Books</ListLink>
        <ListLink to="/teaching/">Teaching</ListLink>
        <ListLink to="/articles/">Publications</ListLink>
        <ListLink to="/news/">News</ListLink>
        <ListLink to="/japanese/">日本語</ListLink>

      </SiteNav>
      <BurgerNav>
        <ul class="">
          <ListLink to="/">Home</ListLink>
          <ListLink to="/books/">Books</ListLink>
          <ListLink to="/teaching/">Teaching</ListLink>
          <ListLink to="/articles/">Publications</ListLink>
          <ListLink to="/japanese/">日本語</ListLink>
        </ul>
      </BurgerNav>
    </div>
  </div>


)

export default ({ children, footer, title = "", headerImageData = false, headerContent }) => (
  <div>
    <header id="header">
      {(headerImageData !== null && <BackgroundImage
      Tag="div"
      className={"header"}
      fluid={headerImageData}
      backgroundColor={`#222222`}
      >
        <Navigation></Navigation>
        {(title !== "" && <div class="block"><h1 class="title" dangerouslySetInnerHTML={{ __html: title }} /></div>)}
        {headerContent}
      </BackgroundImage>)}

      {(headerImageData === null && <div className="header">
        <Navigation></Navigation>
        {(title !== "" && <div class="block"><h1 class="title" dangerouslySetInnerHTML={{ __html: title }} /></div>)}
        {headerContent}
      </div>)}


    </header>

    <div id="content">{children}</div>
    <footer>

      <div class="block">
        <h2>Contact</h2>
        <div dangerouslySetInnerHTML={{ __html: footer.content }} />
      </div>
    </footer>
  </div>
)
