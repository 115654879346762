import React  from "react"

const LinkButton = ({ value }) => {

  if ( value !== null && value !== "" ) {
    
    return <a href={value} class="link"/>
  } else {
    return ""
  }
}


const Articles = ({ value }) => (
  <div class="articles">
    {value.map(article => (
      <Article value={article} key={article.id} />
    ))}
  </div>
)

const Article = ({ value }) => {

  return (
    <div class="item">
      <span class="inner">
        <h4 dangerouslySetInnerHTML={{ __html: value.title }} />
        <span class="pub" dangerouslySetInnerHTML={{ __html: value.publication }} />
        <span dangerouslySetInnerHTML={{ __html: value.year }} />
        <LinkButton value={value.link}></LinkButton>
      </span>
      
    </div>
  )
}

export default Articles
